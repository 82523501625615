import React, { Component } from "react"
import global from "../../../images/global.png"
import robot from "../../../images/robot.png"
import android from "../../../images/android.png"
import { COLORS } from "../../../utils/Colors"

export default class Overview extends Component {
  getRow(contentLeft, content, image) {
    return (
      <div
        className="d-inline-block col-12"
        style={{
          paddingTop: 15,
          paddingBottom: 15,
          position: "relative",
        }}
      >
        <div
          className="p-0 d-inline-block col-12 col-md-2"
          style={{
            float: contentLeft ? "right" : "left",
          }}
        >
          <div
            className={
              contentLeft
                ? "d-flex justify-content-center"
                : "d-flex justify-content-center justify-content-md-end"
            }
          >
            <img
              src={image}
              alt=""
              style={{
                width: 100,
                height: 100,
                float: contentLeft ? "none" : "right",
                justifyContent: "center",
              }}
            />
          </div>
        </div>
        <div
          className="d-inline-block col-12 col-md-10"
          style={{
            float: contentLeft ? "left" : "right",
            textAlign: contentLeft ? "start" : "end",
          }}
        >
          {contentLeft && (
            <span className="d-none d-md-inline-block col-md-1" />
          )}
          <div
            className="d-inline-block col-md-10"
            style={{
              // fontSize: 14,
              color: COLORS.CONTACT_DETAILS_HEADING,
              padding: 0,
              paddingTop: 15,
              fontFamily: "SFProSmBold",
            }}
          >
            {content}
          </div>
          {!contentLeft && <span className="col-md-2" />}
        </div>
      </div>
    )
  }
  render() {
    return (
      <div className="row" style={styles.container}>
        <div className="col-12 tiltLeft2" style={styles.subContainer}>
          <div
            className="col-12 tiltRight2"
            style={{ paddingTop: 50, paddingBottom: 50 }}
          >
            {this.getRow(
              true,
              "People you work with, whether in Riktam or outside will be among the best in their fields and you will learn much from them on a day to day basis. When you’re not on a client project you will have the freedom to work on your area of interest – be it picking up new skills, learning a whole new language/stack, or your passion project.",
              global
            )}
            {this.getRow(
              false,
              "We have a friendly, collegial (not college-like!) atmosphere with few restrictions, and with the expectation that team members take ownership of their projects, their personal development and their careers.",
              robot
            )}
            {this.getRow(
              true,
              "In case you’re wondering … we work on native iOS (both Obj C and Swift) and Android (Java and Kotlin), Python, NodeJS, PHP, Ruby on Rails, NodeJS, Python, React, AngularJS and other technologies. In addition to mobile and web applications, we also work on Machine Learning and IoT.",
              android
            )}
          </div>
        </div>
      </div>
    )
  }
}

var styles = {
  container: {
    backgroundColor: COLORS.PRIMARY_COLOR,
    paddingTop: 0,
    paddingBottom: 80,
  },
  subContainer: {
    backgroundColor: COLORS.INQUIRY_BACKGROUND,
    //  backgroundColor: "pink"
  },
}
