import React, { Component } from "react"
import startups from "../../../images/startups.png"
import seniorDev from "../../../images/seniorDev.png"
import juniorDev from "../../../images/juniorDev.png"
import training from "../../../images/training.png"
import { COLORS } from "../../../utils/Colors"

export default class GreatOppurtunities extends Component {
  getRow(image, type) {
    return (
      <div className="col-12" style={{ paddingTop: 50, paddingBottom: 20 }}>
        <div className="d-inline-block text-center col-12 col-md-4">
          <span className="d-inline-block col-0 col-md-3" />
          <img
            className="img-fluid"
            src={image}
            alt=""
            style={{ height: 100 }}
          />
        </div>
        <div
          className="pr-0 d-inline-block text-center text-md-right col-12 col-md-8"
          style={styles.detail}
        >
          {type == "senior" ? (
            <div>
              As a{" "}
              <span style={{ color: COLORS.HIGHlIGHTED_TEXT }}>
                senior engineer
              </span>{" "}
              or{" "}
              <span style={{ color: COLORS.HIGHlIGHTED_TEXT }}>architect</span>,
              you will work with clients who themselves are high achievers (and
              backed by prominent VCs). You will be involved in not just
              technical details but also product and business decisions as you
              help bring to life their vision
            </div>
          ) : type == "junior" ? (
            <div>
              As a{" "}
              <span style={{ color: COLORS.HIGHlIGHTED_TEXT }}>
                junior developer
              </span>
              , you will join one of our on-going projects, working under the
              guidance of a senior developer or architect. We broaden your
              technical skills by ensuring you are continuously assigned work
              that challenges you but is within your capability
            </div>
          ) : (
            <div>
              <span style={{ color: COLORS.HIGHlIGHTED_TEXT }}>
                Starting out fresh
              </span>
              , you will undergo a comprehensive boot-camp (3-5 months) that
              will prepare you with real-world skills.
            </div>
          )}
        </div>
      </div>
    )
  }
  render() {
    return (
      <div className="row" style={styles.container}>
        <div className="col-12" style={styles.subContainer}>
          <div className="d-inline-block">
            <div
              className="d-inline-block col-12 col-md-6"
              style={styles.image}
            >
              <div className="d-flex justify-content-center">
                <img
                  className="img-fluid"
                  style={{ height: 180 }}
                  src={startups}
                  alt=""
                />
              </div>
            </div>
            <div className="d-inline-block col-12 col-md-6" style={styles.form}>
              <div
                style={{
                  color: COLORS.THEME_COLOR,
                  fontSize: 28,
                  fontWeight: "bold",
                  lineHeight: 1.2,
                  // letterSpacing: -0.4,
                  paddingTop: 80,
                  // fontFamily: "SFProHvy",
                }}
                className="d-inline-block col-sm-10 col-md-11"
              >
                GREAT
                <div
                  style={{
                    fontSize: 38,
                    // fontWeight: "bold",
                    // fontFamily: "SFProReg",
                    // letterSpacing: 0.66,
                  }}
                >
                  OPPORTUNITIES
                </div>
                <div style={{ ...styles.detail, paddingTop: 40 }}>
                  You will get opportunities to work with some Really Cool
                  Startups, as well as with Billion Dollar brands, and we will
                  groom you to be a world-class developer every step of the way
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            {this.getRow(seniorDev, "senior")}
            {this.getRow(juniorDev, "junior")}
            {this.getRow(training, "training")}
          </div>
        </div>
      </div>
    )
  }
}

var styles = {
  container: {
    backgroundColor: COLORS.PRIMARY_COLOR,
  },
  subContainer: {
    paddingBottom: 80,
    //  backgroundColor: "pink"
  },
  detail: {
    lineHeight: 1.2,
    // letterSpacing: -0.22,
    paddingTop: 15,
    fontSize: 16,
    color: COLORS.CONTACT_DETAILS_HEADING,
    fontFamily: "SFProSmBold",
  },

  image: {
    position: "relative",
    float: "left",
    paddingTop: 80,
    // backgroundColor: "red",
  },
  form: {
    position: "relative",
    float: "right",
    // backgroundColor: "yellow",
    textAlign: "end",
  },
}
