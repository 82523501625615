import React, { Component } from "react"
import workPlace from "../../../images/workPlace.png"
import { COLORS } from "../../../utils/Colors"

export default class WorkingAtRiktam extends Component {
  render() {
    return (
      <div className="row" style={styles.container}>
        <div className="col-12" style={styles.subContainer}>
          <div className="col-12 col-md-6" style={styles.image}>
            <div className="d-flex justify-content-center">
              <img
                className="img-fluid"
                style={{ height: 250 }}
                src={workPlace}
                alt=""
              />
            </div>
          </div>
          <div className="col-12 col-md-6" style={styles.form}>
            <span className="d-inline-block col-sm-2" />
            <div
              style={{
                color: COLORS.THEME_COLOR,
                fontSize: 22,
                lineHeight: 1.2,
                // letterSpacing: -0.4,
                paddingTop: 60,
                fontFamily: "SFProHvy",
              }}
              className="pl-1 d-inline-block col-sm-10 col-md-8"
            >
              STRONG FOUNDATIONS, CHALLENGING WORK
              <div
                style={{
                  fontSize: 46,
                  // fontWeight: "bold",
                  fontFamily: "SFProReg",
                  // letterSpacing: 0.66,
                }}
              >
                WORKING AT RIKTAM
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6" style={styles.form}>
            <span className="d-none d-sm-inline-block col-sm-2" />
            <div
              className="pl-1 d-inline-block col-10"
              style={{
                fontSize: 16,
                color: COLORS.CONTACT_DETAILS_HEADING,
                paddingTop: 15,
                fontFamily: "SFProSmBold",
              }}
            >
              Riktam offers exciting careers in technology.
            </div>
            <span className="d-none d-sm-inline-block col-sm-2" />
            <div
              style={{
                // fontSize: 14,
                color: COLORS.CONTACT_DETAILS_HEADING,
                lineHeight: 1.2,
                fontFamily: "SFProSmBold",
                // letterSpacing: -0.4,
              }}
              className="pl-1 d-inline-block col-10"
            >
              If you are sharp, if you love solving problems, if you seek the
              best answers and not just the first answers, if you are a
              self-starter who can work with minimal structure, if you are
              responsible, professional & personable, and if technology is a
              passion … Riktam might just be the right place for you.
            </div>
          </div>
        </div>
      </div>
    )
  }
}

var styles = {
  container: {
    backgroundColor: COLORS.INQUIRY_BACKGROUND,
  },
  subContainer: { paddingBottom: 60 },

  image: {
    position: "relative",
    float: "right",
    paddingTop: 60,
  },
  form: {
    position: "relative",
    float: "left",
  },
}
