import React, { Component } from "react"
import { COLORS } from "../../../utils/Colors"
import WorkingAtRiktam from "./WorkingAtRiktam"
import GreatOppurtunities from "./GreatOppurtunities"
import Overview from "./Overview"

export default class Careers extends Component {
  render() {
    return (
      <div className="container-fluid">
        <WorkingAtRiktam />
        <GreatOppurtunities />
        <Overview />
      </div>
    )
  }
}
