import React, { Component } from "react"
import CareersCmp from "../components/Description/Careers/Careers"
import Layout from "../components/Layout"

export default function Careers() {
    return (
        <Layout>
            <CareersCmp />
        </Layout>
    )
}
